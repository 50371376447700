<template>
  <div class="content">
    <el-form inline :model="search" class="tb-form" >
      <el-form-item label="姓名：">
        <el-input v-model="search.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input v-model="search.phone" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="add">
      <el-button class="tb-button" type="primary" @click="add">
        <i class="tb-icon-plus" />新增推广人
      </el-button>
    </div>
    <div class="tablebox">
      <el-table :data="tableData" class="tb-table" >
        <el-table-column prop="user_name" label="姓名" />
        <el-table-column prop="phone" label="推广人微信号(手机号)" />
        <el-table-column label="累计获得佣金">
          <template slot-scope="scope">
            {{scope.row.total_amount}}
          </template>
        </el-table-column>
        <el-table-column label="当前佣金金额/可提现金额">
          <template slot-scope="{ row }">
            {{ row.earn_amount }}/<span style="color: #1890ff">{{
              row.current_amount
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="140px">
          <template slot-scope="{ row }">
            <router-link :to="`/SellerOrderList?seller_id=${row.user_id}`" class="edit"
              >查看佣金订单</router-link>
            <div class="space" />
            <router-link :to="`/SellerManage/withdrawList?name=${row.user_name}`" class="edit">查看提现流水</router-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="tb-pagination"
        layout="prev, pager, next"
        :current-page.sync="page.no + 1"
        :page-size="page.size"
        :total="page.total"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SellerManageList",
  data() {
    return {
      search: {
        name: "",
        phone: "",
      },
      tableData: [],
      page: {
        no: 0,
        size: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.search.name = "";
      this.search.phone = "";
      this.onSearch();
    },
    onSearch() {
      this.page.no = 0;
      this.getList();
    },
    add() {
      this.$router.push("/SellerManage/add");
    },
    async getList() {
      let vm = this;
      let { no, size } = this.page;
      let { name, phone } = this.search;
      const params = {
        name,
        phone,
        page_size: size,
        page_no: no,
      };
      const url = "admin/seller/list";
      const data = await this.$https.get(url, { params });
      if (!data) return;
      data.list.forEach((it) => {
        it.total_amount = vm.$np.divide(it.total_amount, 100);
        it.current_amount = vm.$np.divide(it.current_amount, 100);
        it.earn_amount = vm.$np.divide(it.earn_amount, 100);
      });
      this.page.total = data.total_count;
      this.tableData = data.list;
    },
    pageChange(no) {
      this.page.no = no - 1;
      this.getList();
    },
  },
};
</script>
<style lang='scss' scoped>
.space {
  display: inline-block;
  width: 1px;
  height: 14px;
  background: #e9e9e9;
  margin: 8px 8px 0 8px;
}
</style>